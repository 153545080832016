<template>
  <div>
    <b-tabs justified pills>

      <b-tab ref="about" title="Sobre a Construtora" active>

        <template #title class="hgh">
          <div
            v-if="!dataExternal.about && notifyMsg.data_about_notify"
            class="notify"> ! </div>
          <span :class="{ notify_input_blank: !dataExternal.about && notifyMsg.data_about_notify }">Sobre a Construtora</span>
        </template>

        <b-row>
          <b-col>
            <h4>Sobre a Construtora</h4>
          </b-col>
        </b-row>
        <b-row>
          <b-col>

            <validation-provider #default="{ errors }" name="Sobre a construtora" rules="required">

              <quill-editor v-model="dataExternal.about" />
              <small class="text-danger">{{ errors[0] }}</small>

            </validation-provider>

          </b-col>
        </b-row>
      </b-tab>

      <b-tab title="Regiões de Atuação">
        <template #title class="hgh">
          <div
            v-if="!dataExternal.actingRegions && notifyMsg.data_actingRegions_notify"
            class="notify"> ! </div>
          <span :class="{ notify_input_blank: !dataExternal.actingRegions && notifyMsg.data_actingRegions_notify }">Regiões de Atuação</span>
        </template>

        <b-row>
          <b-col>
            <h4>Regiões de atuação</h4>
          </b-col>
        </b-row>
        <b-row>
          <b-col>

            <validation-provider #default="{ errors }" name="Regiões de atuação" rules="required">

              <quill-editor ref="actingRegions" v-model="dataExternal.actingRegions" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>

          </b-col>
        </b-row>
      </b-tab>

      <b-tab title="Prêmios e Certificações">
        <b-row>
          <b-col>
            <h4>Prêmios e Certificações</h4>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <text-editor ref="data_awards" :text="dataExternal.awards" :disable="disableChild" />
          </b-col>
        </b-row>
      </b-tab>

      <b-tab title="Documentos">
        <template #title class="hgh">
          <div
            v-if="!formDataChild.status && notifyMsg.data_statusDocs_notify"
            class="notify"> ! </div>
          <span :class="{ notify_input_blank: !formDataChild.status && notifyMsg.data_statusDocs_notify }">Documentos</span>
        </template>

        <b-row>
          <b-col>
            <h4>Documentos Recebidos</h4>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <upload-docs ref="data_docs" :files="dataExternal.attachments" :status="data_status_files" :disable="disableChild" />
          </b-col>
        </b-row>

      </b-tab>
    </b-tabs>

  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import { required } from '@validations'

// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'

import {
  BTabs,
  BTab,
  BRow,
  BCol,
} from 'bootstrap-vue'
import TextEditor from './TextEditor.vue'
import UploadDocs from './CompanyFilesDeliberation.vue'

export default {
  components: {
    quillEditor,
    BTabs,
    BTab,
    TextEditor,
    UploadDocs,
    BRow,
    BCol,
    ValidationProvider,
  },

  props: ['data_external', 'data_status', 'data_status_files', 'disable', 'notify'],

  data() {
    return {
      dataExternal: {},
      required,

      formDataChild: {
        about: '',
        actingRegions: '',
        awards: '',
        files: '',
        status: {},
        status_files: {},
      },
      disableChild: 0,
      notifyMsg: {},
    }
  },

  computed: {
    changeData() {
      const { data_external, data_status, data_status_files, disable, notify } = this
      return {
        data_external,
        data_status,
        data_status_files,
        disable,
        notify,
      }
    },
  },

  watch: {
    changeData: {
      handler(val) {
        this.dataExternal = val.data_external
        this.status = val.data_status
        this.status_files = val.data_status_files
        this.disableChild = val.disable
        this.notifyMsg = val.notify
      },
      deep: true,
      immediate: true,
    },
  },

  mounted() {
    this.data()
  },

  methods: {
    data() {
      this.formDataChild.about = this.$refs.data_about
      this.formDataChild.actingRegions = this.$refs.data_actingRegions
      this.formDataChild.awards = this.$refs.data_awards
      this.formDataChild.status = this.$refs.data_docs.dataFormChild
    },

  },
}
</script>

<style>
  .inpf {
    height: 100px;
    width: 100px;
  }
  .imgt {
    height: 150px;
    width: 150px;
  }
  .notify {
    position: absolute;
    margin-top: -6px;
    background-color: #ff2e2e;
    color: #fff;
    text-align: center;
    padding: 5px;
    border-radius: 20px;
    height: 25px;
    width: 25px;
  }
  .notify_input_blank {
    color: #ff2e2e;
    font-weight: 700;
  }
</style>
