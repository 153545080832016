import Vue from 'vue'

const ConstructionCompanyDataService = {

  GetByUserConstructionCompanyAdm(id) {
    return Vue.prototype.$http.get(`api/services/app/UserConstructionCompanyDatas/GetByUserConstructionCompanyAdm?idCompany=${id}`,
      this.reset, {
        timeout: 300000,
      })
  },

  CreateOrEdit(form) {
    return Vue.prototype.$http.post('/api/services/app/UserConstructionCompanyInfos/CreateOrEdit', form)
  },
}

export default ConstructionCompanyDataService
