const toast = {
  methods: {
    msgError(msg) {
      this.$swal({
        icon: 'error',
        title: 'Erro',
        showConfirmButton: false,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
        html: `${msg}
                <br> <br> <br>
                <button type="button" variant="secondary" class="btn btn-secondary btn-no swl-cstm-btn-no-jst-prceed">Fechar</button>`,
        showCancelButton: false,
        onBeforeOpen: () => {
          const no = document.querySelector('.btn-no')

          no.addEventListener('click', () => {
            this.$swal.close()
          })
        },
      })
    },

    msgSuccess(msg = '') {
      this.$swal({
        icon: 'success',
        title: 'Sucesso',
        showConfirmButton: false,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
        html: `${msg}
                <br> <br> <br>
                <button type="button" variant="secondary" class="btn btn-secondary btn-no swl-cstm-btn-no-jst-prceed">Fechar</button>`,
        showCancelButton: false,
        onBeforeOpen: () => {
          const no = document.querySelector('.btn-no')

          no.addEventListener('click', () => {
            this.$swal.close()
          })
        },
      })
    },

    msgWarning(msg) {
      this.$swal({
        icon: 'warning',
        title: 'Atenção',
        showConfirmButton: false,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
        html: `${msg}
                <br> <br> <br>
                <button type="button" variant="secondary" class="btn btn-secondary btn-no swl-cstm-btn-no-jst-prceed">Fechar</button>`,
        showCancelButton: false,
        onBeforeOpen: () => {
          const no = document.querySelector('.btn-no')

          no.addEventListener('click', () => {
            this.$swal.close()
          })
        },
      })
    },

    // No Confirm Button
    msgSuccessNoConfirm(msg) {
      this.$swal({
        icon: 'success',
        title: 'Sucesso',
        showConfirmButton: false,
        timer: 2600,
        buttonsStyling: false,
        html: `${msg} <br />`,
        showCancelButton: false,
      })
    },

    msgIsOpen() {
      try {
        // swal 2.0
        return this.$swal.getState() === 'isOpen'
      } catch (e) {
        return document.getElementsByClassName('sweet-alert.visible').length > 0
      }
    },

    confirmDelete(message) {
      return this.$bvModal.msgBoxConfirm([this.$createElement('div', { domProps: { innerHTML: message } })], {
        title: 'Confirma excluir?',
        cancelTitle: 'Cancelar',
        okVariant: 'danger',
        centered: true,
        okTitle: 'Sim',
      })
    },

    msgLoading(msg) {
      this.$swal({
        icon: 'info',
        title: 'Aguarde...',
        showConfirmButton: false,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        html: msg,
        buttonsStyling: false,
        showCancelButton: false,
        onBeforeOpen: () => {
          const no = document.querySelector('.btn-no')
          if (no) {
            no.addEventListener('click', () => {
              this.$swal.close()
            })
          }
        },
      })
    },
  },
}

export default toast
