import Vue from 'vue'

const CompanyCommentsService = {

    getAll(companyId) {
      return Vue.prototype.$http.get(`api/services/app/ConstructionCompanyComments/GetAll?companyId=${companyId}`,
        this.reset, {
          timeout: 300000,
      })
  },

  createOrEdit(form) {
    return Vue.prototype.$http.post('/api/services/app/ConstructionCompanyComments/CreateOrEdit', form)
  },
  delete(id) {
    return Vue.prototype.$http.delete('/api/services/app/ConstructionCompanyComments/Delete?Id='+id)
  },
}

export default CompanyCommentsService
