import Vue from 'vue'

const ConstructionCompanyInfoService = {

  GetInfoByUserConstructionCompanyAdm(id) {
    return Vue.prototype.$http.get(`/api/services/app/UserConstructionCompanyInfos/GetInfoByUserConstructionCompanyAdm?idCompany=${id}`,
      this.reset, {
        timeout: 300000,
      })
  },

  CreateOrEdit(form) {
    return Vue.prototype.$http.post('/api/services/app/UserConstructionCompanyInfos/CreateOrEdit', form)
  },
}

export default ConstructionCompanyInfoService
