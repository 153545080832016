<template>
  <b-row>

      <b-col cols="12">
        <b-card-group deck class="mb-0 mt-2">

          <!-- card 1 -->
          <b-card
            img-alt="Card image cap"
            style="max-width: 15rem;"
            border-variant="light"
            class="img-size"
            no-body
          >
            <b-card-body class="title-upload">
              <b-card-title class="text-center ft-size">Cartão CNPJ</b-card-title>
            </b-card-body>

            <b-link id="link-cnpj" v-if="attachments.binaryCnpj.id" class="detailing__cursor" :to="{ name: 'company-view-file' }" target="_blank" @click="setDataFile({id: attachments.binaryCnpj.id, data: attachments.binaryCnpj})">
              <b-img fluid :src="require('@/assets/images/upload/upload_transparent_on.png')" />
            </b-link>
            <b-img id="link-cnpj-disabled" v-else fluid :src="require('@/assets/images/upload/upload_transparent.png')" />

            <b-card-body>
              <b-card-text>
                <validation-provider #default="{ errors }" name="Cartão CNPJ" rules="required">

                  <b-form-select
                    id="situationCnpj"
                    v-model="dataFormChild.situationCnpj"
                    :disabled="disableChild === 1"
                    :options="status_value" />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>

              </b-card-text>
            </b-card-body>
          </b-card>

          <!-- card 2 -->
          <b-card
            img-alt="Card image cap"
            style="max-width: 15rem;"
            border-variant="light"
            class="img-size"
            no-body
          >
            <b-card-body class="title-upload">
              <b-card-title class="text-center ft-size">Certidão Negativa Municipal</b-card-title>
            </b-card-body>

            <b-link id="link-municipal" v-if="attachments.negativeMunicipalCertificateFile !== null && attachments.negativeMunicipalCertificateFile.bytes !== ''" class="detailing__cursor" :to="{ name: 'company-view-file' }" target="_blank" @click="setDataFile({id: attachments.negativeMunicipalCertificateFile.id, data: attachments.negativeMunicipalCertificateFile, fileKey: 'filecnpj'})">
              <b-img fluid :src="require('@/assets/images/upload/upload_transparent_on.png')" />
            </b-link>
            <b-img id="link-municipal-disabled" v-else fluid :src="require('@/assets/images/upload/upload_transparent.png')" />

            <b-card-body>
              <b-card-text>

                <validation-provider #default="{ errors }" name="Certidão Negativa Municipal" rules="required">
                  <b-form-select
                    id="negativeMunicipalCertificateSituation"
                    v-model="dataFormChild.negativeMunicipalCertificateSituation"
                    :disabled="disableChild === 1 || attachments.negativeMunicipalCertificateFile === null || attachments.negativeMunicipalCertificateFile.bytes === ''"
                    :options="status_value" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-card-text>
            </b-card-body>
          </b-card>

          <!-- card 3 -->
          <b-card
            img-alt="Card image cap"
            style="max-width: 15rem;"
            border-variant="light"
            class="img-size"
            no-body
          >
            <b-card-body class="title-upload">
              <b-card-title class="text-center ft-size">Certidão Negativa Estadual</b-card-title>
            </b-card-body>

            <b-link id="link-state" v-if="attachments.negativeStateCertificateFile !== null && attachments.negativeStateCertificateFile.bytes !== ''" class="detailing__cursor" :to="{ name: 'company-view-file' }" target="_blank" @click="setDataFile({id: attachments.negativeStateCertificateFile.id, data:attachments.negativeStateCertificateFile, fileKey: 'filecnpj'})">
              <b-img fluid :src="require('@/assets/images/upload/upload_transparent_on.png')" />
            </b-link>
            <b-img  id="link-state-disabled" v-else fluid :src="require('@/assets/images/upload/upload_transparent.png')" />

            <b-card-body>
              <b-card-text>

                <validation-provider #default="{ errors }" name="Certidão Negativa Estadual" rules="required">

                  <b-form-select
                    id="negativeStateCertificateSituation"
                    v-model="dataFormChild.negativeStateCertificateSituation"
                    :disabled="disableChild === 1 || attachments.negativeStateCertificateFile === null || attachments.negativeStateCertificateFile.bytes === ''"
                    :options="status_value" />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>

              </b-card-text>
            </b-card-body>
          </b-card>

          <!-- card 4 -->
          <b-card
            img-alt="Card image cap"
            style="max-width: 15rem;"
            border-variant="light"
            class="img-size"
            no-body
          >
            <b-card-body class="title-upload">
              <b-card-title class="text-center ft-size">Certidão Negativa Federal</b-card-title>
            </b-card-body>
            <b-link id="link-federal" v-if="attachments.negativeFederalCertificateFile !== null && attachments.negativeFederalCertificateFile.bytes !== ''" class="detailing__cursor" :to="{ name: 'company-view-file' }" target="_blank" @click="setDataFile({id: attachments.negativeFederalCertificateFile.id, data: attachments.negativeFederalCertificateFile, fileKey: 'filecnpj'})">
              <b-img fluid :src="require('@/assets/images/upload/upload_transparent_on.png')" />
            </b-link>
              <b-img  id="link-federal-disabled" v-else fluid :src="require('@/assets/images/upload/upload_transparent.png')" />
            <b-card-body>
              <b-card-text>

              <validation-provider #default="{ errors }" name="Certidão Negativa Federal" rules="required">

                <b-form-select
                  id="negativeFederalCertificateSituation"
                  v-model="dataFormChild.negativeFederalCertificateSituation"
                  :disabled="disableChild === 1 || attachments.negativeFederalCertificateFile === null|| attachments.negativeFederalCertificateFile.bytes === ''"
                  :options="status_value" />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>

              </b-card-text>
            </b-card-body>
          </b-card>

        </b-card-group>
      </b-col>

  </b-row>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import {
  BRow, BCol, BCardGroup, BCard, BCardText, BCardBody, BCardTitle, BImg, BLink, BFormSelect,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCardGroup,
    BCard,
    BCardText,
    BCardBody,
    BCardTitle,
    BImg,
    BLink,
    BFormSelect,
    ValidationProvider,
  },
  props: {
    files: {
      type: Object,
      default: () => {},
    },

    status: {
      type: Object,
      default: () => {},
    },

    disable: {
      type: Number,
      default: 0,
    },
  },

  data: () => ({
    dataFormChild: {
      filecnpj: '',
      id: '',

      situationCnpj: 3,
      negativeMunicipalCertificateSituation: 3,
      negativeStateCertificateSituation: 3,
      negativeFederalCertificateSituation: 3,
    },

    attachments: {
      binaryCnpj: '',
      negativeMunicipalCertificateFile: '',
      negativeStateCertificateFile: '',
      negativeFederalCertificateFile: '',
    },

    status_value: [
      { value: 2, text: 'Pendente Validação' },
      { value: 1, text: 'Aprovado' },
      { value: 3, text: 'Pendente Informação' },
      { value: 4, text: 'Rejeitado' },
    ],

    status_value_disabled: [
      { value: 3, text: 'Pendente Informação' }
    ],

    required,

    disableChild: 0,
    warning: 'Msg',
  }),

  computed: {
    changeData() {
      const { files, status, disable } = this
      return {
        files,
        status,
        disable,
      }
    },
  },

  watch: {
    changeData: {
      handler(val) {
        this.attachments.binaryCnpj = val.files.binaryCnpj
        this.attachments.negativeMunicipalCertificateFile = val.files.negativeMunicipalCertificateFile
        this.attachments.negativeStateCertificateFile = val.files.negativeStateCertificateFile
        this.attachments.negativeFederalCertificateFile = val.files.negativeFederalCertificateFile

        this.dataFormChild.situationCnpj = val.status.situationCnpj || 3
        this.dataFormChild.negativeMunicipalCertificateSituation = val.status.negativeMunicipalCertificateSituation || 3
        this.dataFormChild.negativeStateCertificateSituation = val.status.negativeStateCertificateSituation || 3
        this.dataFormChild.negativeFederalCertificateSituation = val.status.negativeFederalCertificateSituation || 3

        this.disableChild = val.disable
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {

    setDataFile(data) {
      localStorage.setItem('fileDataView', JSON.stringify(data))
    },

    // warning
    msgWarning() {
      this.$swal({
        icon: 'info',
        title: 'Atenção',
        showConfirmButton: false,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
        html: `${this.warning}
            <br> <br> <br>
            <button type="button" variant="secondary" class="btn btn-secondary btn-no swl-cstm-btn-no-jst-prceed">Fechar</button>`,
        showCancelButton: false,
        onBeforeOpen: () => {
          const no = document.querySelector('.btn-no')

          no.addEventListener('click', () => {
            this.$swal.close()
          })
        },
      })
    },

    clearFiles(event) {
      this.$refs[event.target.id].reset()
    },

    // success
    msgSuccess() {
      this.$swal({
        icon: 'success',
        title: 'Dados registrados com sucesso',
        showConfirmButton: false,
        timer: 2600,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },

    // error
    msgError() {
      this.$swal({
        icon: 'error',
        title: 'Erro',
        showConfirmButton: false,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
        html: `${this.error}
            <br> <br> <br>
            <button type="button" variant="secondary" class="btn btn-secondary btn-no swl-cstm-btn-no-jst-prceed">Fechar</button>`,
        showCancelButton: false,
        onBeforeOpen: () => {
          const no = document.querySelector('.btn-no')

          no.addEventListener('click', () => {
            this.$swal.close()
          })
        },
      })
    },
  },
}
</script>
<style scoped>
  div.img-size > img,
  div.img-size > a img {
    padding: 10px 35px
  }
  .ft-size {
    font-size: 12px;
  }
  .border-light {
      border: 1px solid #e5e5e5 !important;
  }
  .title-upload {
    border-bottom: 1px solid #e5e5e5;
    padding: 22px 0 0 0;
    margin: 0 0 15px 0;
    background-color: #e3e3e3;
  }
</style>
