import Moment from 'moment/moment'
import toast from '@/mixins/toast'

const formatter = {

  minxs:[toast],

  methods: {
    formatCurrencyPrice(value) {
      return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value)
    },

    formatLocaleString(value) {
      return value.toLocaleString()
    },
  },
  
  validateDate(id, context){
    let element = document.getElementById(id)
    const date = Moment(element.value, 'DD/MM/YYYY')
    if(date._isValid === false){
      element.value = null
      context.msgError('Data Inválida')
    }
  },

  formatDate(dateString) {
    const date = new Date(dateString)
    return new Intl.DateTimeFormat('pt-BR').format(date)
  },
  
  flatPickrDateFormat(v) {
    return v ? Moment(v, 'YYYY-MM-DD').format('DD/MM/YYYY') : undefined
  },

  formatToDate(v, includeTimezone = true) {
    return Moment(v, 'DD/MM/YYYY').format(includeTimezone? 'YYYY-MM-DDT00:00:00' : 'YYYY-MM-DD')
  },
  
  formatMaskMoney(value) {
    // eslint-disable-next-line no-useless-escape
    value = value.toString().replace('R$ ', '')
    let temPonto = value.includes('.')
    while(temPonto){
      value = value.replace('.', '')
      temPonto = value.includes('.')
    }
    value = value.replace(',', '.')
    if (/^(\-|\+)?([0-9]+(\.[0-9]+)?|Infinity)$/.test(value)) {
      return Number(value)
    }
    return NaN
  },
}

export default formatter
