<template>
  <div>
    <b-form @submit.prevent>
      <div class="card">
        <validation-observer ref="registerForm">
          <b-card-body>
            <b-row>
              <b-col>
                <h3>Dados da Construtora</h3>
              </b-col>
            </b-row>

            <b-row>
              <b-col sm="3">
                <b-form-group label="CNPJ" label-for="number-cpf-cnpj">
                  <!-- <b-form-input id="number-cpf-cnpj" placeholder="05.533.541/0001-07" /> -->
                  <cleave id="number-cpf-cnpj" v-model="formData.numberCpfCnpj" class="form-control" :raw="false" :options="cleaveOptions.cnpj" placeholder="05.533.541/0001-07" disabled />
                </b-form-group>
              </b-col>

              <b-col>
                <b-form-group label="Razão Social" label-for="name">
                  <validation-provider #default="{ errors }" name="Razão Social" rules="required">
                    <b-form-input id="name" v-model="formData.name" placeholder="ex. Lorenge Empreendimentos Imobiliarios Ltda" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <!--<b-col>
                <b-form-group label="Endereço" label-for="address">
                  <validation-provider #default="{ errors }" name="Endereço" rules="required">
                    <b-form-input id="address" v-model="formData.address" placeholder="Rua dos Construtores, 250 - Bairro dos Construtores - Vitória - ES - 29.250-354" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col> -->

              <b-col sm="3">
          <!--Cep-->
          <b-form-group label="Cep" label-for="Cep">
                <validation-provider #default="{ errors }" name="Cep" rules="required">
                  <b-form-input
                    id="Cep"
                    v-model="formData.cep"
                    v-validate="{ required: true, regex: '/^[0-9]{5}-[0-9]{3}', min: 2 }"
                    placeholder="69040-000"
                    :disabled="formData.situation === 2" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          <b-col sm="9">
          <!--Rua-->
          <b-form-group label="Rua" label-for="address">
                <validation-provider #default="{ errors }" name="Rua" rules="required">
                  <b-form-input
                    id="Rua"
                    v-model="formData.rua"
                    placeholder="Rua dos Construtores, 250 - Bairro dos Construtores - Vitória - ES - 29.250-354"
                    :disabled="formData.situation === 2" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
          <!--Numero-->
          <b-col sm="3">
              <b-form-group label="Número" label-for="register-numero">
                <validation-provider #default="{ errors }" name="Numero" rules="required">
                  <b-form-input
                    id="register-numero"
                    v-model="formData.numero"
                    type="number"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Ex.: 01" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!--Complemento-->
            <b-col>
              <b-form-group label="Complemento" label-for="register-complemento">
                <validation-provider #default="{ errors }" name="Complemento" rules="required">
                  <b-form-input
                    id="register-complemento"
                    v-model="formData.complemento"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Ex,: Apto. 10 Bloco 4B" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
          <!-- Bairro -->
          <b-col>
              <b-form-group label="Bairro" label-for="register-bairro">
                <validation-provider #default="{ errors }" name="Bairro" rules="required">
                  <b-form-input
                    id="register-bairro"
                    v-model="formData.bairro"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Ex.: Lago Norte" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!--Cidade-->
            <b-col>
              <b-form-group label="Cidade" label-for="register-cidade">
                <validation-provider #default="{ errors }" name="Cidade" rules="required">
                  <b-form-input
                    id="register-cidade"
                    v-model="formData.cidade"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Ex.: Brasilia" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

             <!--Estado-->
             <b-col>
              <b-form-group label="Estado" label-for="register-uf">
                <validation-provider
                  #default="{ errors }"
                  name="uf"
                  rules="required">
                  <b-form-select
                  id="register-uf"
                  v-model="formData.uf"
                  label="title"
                  :options="listaUf"
                  :reduce="title => title.value"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

              <b-col sm="4">
                <b-form-group label="Telefone" label-for="phone-number">
                  <validation-provider #default="{ errors }" name="Telefone" rules="required">
                    <b-form-input id="phone-number" v-model="formData.phoneNumber" v-mask="['(##) #####-####']" class="form-control" :raw="false" placeholder="(11) 99999-9999" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col sm="3">
                <b-form-group label="E-mail" label-for="email-address">
                  <validation-provider #default="{ errors }" name="E-mail" rules="required">
                    <b-form-input id="email-address" v-model="formData.emailAddress" placeholder="contato@lorenge.com.br" disabled />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col sm="3">
                <b-form-group label="Banco" label-for="bank">
                  <validation-provider #default="{ errors }" name="Banco" rules="required">
                    <b-form-select id="bank" v-model="formData.bank.bankId" label="title" :options="listBanks" required />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col sm="2">
                <b-form-group label="Agência" label-for="agency">
                  <validation-provider #default="{ errors }" name="Agência" rules="required">
                    <b-form-input id="agency" type="number" v-model="formData.bank.agency" placeholder="2255" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col sm="1">
                <b-form-group label="Dígito" label-for="agency-digit">
                  <validation-provider #default="{ errors }" name="Dígito Agência" rules="required">
                    <b-form-input id="agency-digit" v-model="formData.bank.agencyDigit" v-mask="['#']" type="number" placeholder="5" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col sm="2">
                <b-form-group label="Conta sem dígito" label-for="checking-account">
                  <validation-provider #default="{ errors }" name="Conta sem dígito" rules="required">
                    <b-form-input id="checking-account" v-model="formData.bank.checkingAccount" type="number" placeholder="584126489" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col sm="1">
                <b-form-group label="Dígito" label-for="checking-account-digit">
                  <validation-provider #default="{ errors }" name="Dígito Conta" rules="required">
                    <b-form-input id="checking-account-digit" v-model="formData.bank.checkingAccountDigit" v-mask="['#']" type="number" placeholder="5" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col sm="6">
                <b-form-group label="Website" label-for="website">
                  <validation-provider #default="{ errors }" name="Website" rules="required">
                    <b-input-group>
                      <b-form-input id="website" v-model="formData.external.website" placeholder="https://www.seu-site.com.br" />
                      <b-input-group-append>
                        <b-button variant="outline-primary" @click="goSite()">
                          <feather-icon icon="LinkIcon" size="14" />
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col sm="3">
                <b-form-group label="N° obras entregues" label-for="delivered-works">
                  <validation-provider #default="{ errors }" name="N° obras entregues" rules="required">
                    <b-form-input id="delivered-works" v-model="formData.external.deliveredWorks" type="number" placeholder="1568" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col sm="3">
                <b-form-group label="Ano Fundação" label-for="foundation-year">
                  <validation-provider #default="{ errors }" name="Ano Fundação" rules="required|min:4">
                    <b-form-input id="foundation-year" v-model="formData.external.foundationYear" v-mask="['####']" type="number" placeholder="1980" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!--task #923 
            <b-row>
              <b-col sm="3">
                <b-form-group label="Valores Recebidos" label-for="totalReceived">
                  <money-input ref="totalReceived" id="totalReceived" :value="formData.totalReceived" @update="updateTotalReceived"/>
                </b-form-group>
              </b-col>
            </b-row>
          <b-row> 
            <b-col> -->
              <h3>Dados do Representante</h3>
            </b-col>
          </b-row>
            <b-row>
              <b-col sm="5">
                <b-form-group label="Nome Completo" label-for="account-name">
                  <validation-provider
                    #default="{ errors }"
                    name="Nome"
                    rules="required">
                    <b-form-input
                      v-model="optionsResponsible.name"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Name" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group
                  label-for="example-datanasc"
                  label="Data de Nascimento">
                  <validation-provider
                    #default="{ errors }"
                    name="birthDate"
                    rules="required">
                    <b-form-input 
                      id='nascimento-responsavel'
                      v-mask="['##/##/####']"
                      v-model="optionsResponsible.birthDate" 
                      name="nascimento-responsavel"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Data Nascimento"
                      @change="changeDate(nascimento-responsavel)"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col sm="2">
                <b-form-group label="CPF" label-for="account-cpf">
                  <validation-provider
                    #default="{ errors }"
                    name="CPF"
                    rules="required">
                    <b-form-input
                      v-model="optionsResponsible.cpf"
                      v-mask="'###.###.###-##'"
                      :state="errors.length > 0 ? false : null"
                      placeholder=""
                      />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>          
              <b-col :sm="isModal ? 2 : 3">
                <b-form-group label="RG" label-for="account-rg">
                  <validation-provider
                    #default="{ errors }"
                    name="RG"
                    rules="required">
                    <b-form-input
                      v-model="optionsResponsible.rg"
                      :state="errors.length > 0 ? false : null"
                      placeholder="9999999" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col :sm="isModal ? 4 : 2">
                <b-form-group label="Estado Civíl" label-for="account-ecivil">
                  <validation-provider
                    #default="{ errors }"
                    name="estadocivil"
                    rules="required">
                    <v-select
                      id="account-ecivil"
                      v-model="optionsResponsible.civilState"
                      label="title"
                      :options="estadosCivis"
                      :reduce="title => title.value" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col :md="isModal ? 4 : 3">
                <b-form-group label-for="nacionalidade" label="Nacionalidade">
                  <validation-provider
                    #default="{ errors }"
                    name="Nacionalidade"
                    rules="required">
                    <v-select
                      id="nacionalidade"
                      v-model="optionsResponsible.nationality"
                      label="title"
                      :options="listaNacionalidades"
                      :reduce="title => title.value" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col sm="4">
                <b-form-group label="Profissão" label-for="profession">
                  <validation-provider
                    #default="{ errors }"
                    name="Profissão"
                    rules="required">
                    <b-form-input
                      v-model="optionsResponsible.profession"
                      :state="errors.length > 0 ? false : null"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col sm="3">
                <b-form-group label="Celular" label-for="account-celular">
                  <validation-provider
                    #default="{ errors }"
                    name="Celular"
                    rules="required">
                    <b-form-input
                      v-model="optionsResponsible.phoneNumber"
                      v-mask="['(##) #####-####']"
                      :state="errors.length > 0 ? false : null"
                      placeholder="(99) 99999-9999" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="5">
                <b-form-group label="Rua" label-for="street">
                  <validation-provider
                    #default="{ errors }"
                    name="Rua"
                    rules="required">
                    <b-form-input
                      v-model="optionsResponsible.street"
                      :state="errors.length > 0 ? false : null"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col :sm="2">
                <b-form-group label="Número" label-for="streetNumber">
                  <validation-provider
                    #default="{ errors }"
                    name="Número"
                    rules="required">
                    <b-form-input
                      v-model="optionsResponsible.streetNumber"
                      type="number"
                      :state="errors.length > 0 ? false : null"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col :sm="2">
                <b-form-group label="CEP" label-for="account-cep">
                  <validation-provider
                    #default="{ errors }"
                    name="cep"
                    rules="required">
                    <b-form-input
                      v-model="optionsResponsible.postalCode"
                      v-mask="['#####-###']"
                      v-validate="{ required: true, max: 8 }"
                      name="cep"
                      :state="errors.length > 0 ? false : null"
                      placeholder="69000-000"
                      @blur="buscaCep()" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            <b-col :sm="3">
              <b-form-group label="Bairro" label-for="district">
                <validation-provider
                  #default="{ errors }"
                  name="Bairro"
                  rules="required">
                  <b-form-input
                    v-model="optionsResponsible.district"
                    :state="errors.length > 0 ? false : null"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col :sm="3">
              <b-form-group label="Cidade" label-for="city">
                <validation-provider
                  #default="{ errors }"
                  name="Cidade"
                  rules="required">
                  <b-form-input
                    v-model="optionsResponsible.city"
                    :state="errors.length > 0 ? false : null"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col :sm="2">
              <b-form-group label="UF" label-for="account-uf">
                <validation-provider
                  #default="{ errors }"
                  name="uf"
                  rules="required">

                  <b-form-select
                  id="account-uf"
                  v-model="optionsResponsible.estateId"
                  label="title"
                  :options="listaUf"
                  :reduce="title => title.value"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

            <b-row class="pt-2">
              <b-col>
                <form-wizard-dados-construtora
                  ref="data_form_external"
                  :disable="0"
                  :data_external="formData.external"
                  :data_status="deliberationData"
                  :data_status_files="deliberationDataChild"
                  :notify="notify" />
              </b-col>
            </b-row>
          </b-card-body>

          <hr class="mt-2 mb-1" />

          <b-card-body>
            <b-row>
              <b-col>
                <h4>Avaliação de Rating</h4>
                <small>Defina a nota da construtora</small>
                <small v-if="errorRating === 'show'" class="text-danger error_rating">O campo Rating é obrigatório</small>
              </b-col>
            </b-row>

            <b-row class="pt-2">
              <b-col v-for="r in ratings" :key="r.note" sm="2">
                <b-button v-b-popover.hover.top="r.note" class="btn-lg btn-block" :variant="r.color" @click="rating(r)">{{ r.note }}</b-button>
                  <span
                    v-if="deliberationData.rating.id === r.id"
                    v-show="deliberationData.rating.color === 'danger'"
                    :ref="r.note"
                    class="badge badge-danger"
                    style="margin-top: 10px; display: block">&nbsp;</span>
                  <span
                    v-if="deliberationData.rating.id === r.id"
                    v-show="deliberationData.rating.color === 'warning'"
                    :ref="r.note"
                    class="badge badge-warning"
                    style="margin-top: 10px; display: block">&nbsp;</span>
                  <span
                    v-if="deliberationData.rating.id === r.id"
                    v-show="deliberationData.rating.color === 'success'"
                    :ref="r.note"
                    class="badge badge-success"
                    style="margin-top: 10px; display: block">&nbsp;</span>
              </b-col>
            </b-row>

            <b-row class="pt-2">
              <b-col sm="3">
                <b-form-group label="Status" label-for="status-imovel">
                  <validation-provider #default="{ errors }" name="Status" rules="required">
                    <b-form-select id="status-imovel" v-model="deliberationData.situationProfile" :options="status" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row class="pt-1">
              <b-col>
                <h6>Parecer</h6>
                <text-editor ref="data_content" :disable="0" :text="deliberationData.text" rules="required" />
              </b-col>
            </b-row>
          </b-card-body>

          <b-card-body>
            <b-row class="pt-2" align-h="end">
              <b-col sm="2">
                <b-button class="btn-block" variant="primary" @click="validationForm('save')">Salvar</b-button>
              </b-col>
              <b-col sm="2">
                <router-link v-if="this.$route.params.path === 'company'" :to="{ name: 'company-list' }">
                  <b-button class="btn-block" variant="primary" @click="$router.push('company-list')">Voltar</b-button>
                </router-link>
                <router-link v-else :to="{ name: 'deliberation-company-list' }">
                  <b-button class="btn-block" variant="primary" @click="$router.push('deliberation-company-list')">Voltar</b-button>
                </router-link>
              </b-col>
            </b-row>
          </b-card-body>
        </validation-observer>
        <b-overlay :show="show" no-wrap />
      </div>
    </b-form>
    <b-card>
      <CompanyComents v-if="id != ''" :companyId="id"/>
    </b-card>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import toast from '@/mixins/toast'
import { required, min } from '@validations'
import CompanyComents from '@/views/comments/constructionCompany/CompanyComents.vue'
import MoneyInput from '@/components/MoneyInput.vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'

import {
  BOverlay,
  BRow,
  BCol,
  BForm,
  BFormSelect,
  BFormInput,
  BFormGroup,
  BCard,
  BCardBody,
  BButton,
  VBPopover,
  // BBadge,
  BInputGroup,
  BInputGroupAppend,

} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
import ConstructionCompanyService from '../services/ConstructionCompany/ConstructionCompanyService'
import ConstructionCompanyInfoService from '../services/ConstructionCompany/ConstructionCompanyInfoService'
import ConstructionCompanyDataService from '../services/ConstructionCompany/ConstructionCompanyDataService'
import UserBankService from '../services/User/UserBankService'
import TextEditor from './CompanyAccountText.vue'
import { Portuguese } from 'flatpickr/dist/l10n/pt.js'
import FormWizardDadosConstrutora from './FormDadosDeliberacaoConstrutora.vue'
import UserResponsibleService from '@/services/User/UserResponsibleService'
import StateService from '@/services/StateService'
import formatter from '@/mixins/formatter'
import Moment from 'moment/moment'

export default {
  components: {

    BFormSelect,
    BOverlay,
    BRow,
    BCol,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardBody,
    BFormSelect,
    FormWizardDadosConstrutora,
    Cleave,
    MoneyInput,
    flatPickr,
    TextEditor,
    BButton,
    CompanyComents,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    // eslint-disable-next-line vue/no-unused-components
    Moment,
    vSelect,
  },

  directives: {
    'b-popover': VBPopover,
  },

  mixins: [toast],

  data() {
    return {
      id: this.$route.params.usuario.id,
      danger: 'danger',
      userId: '',
      show: true,
      min,
      ratings: [],
      optionsResponsible:{}, 
      configData: {
        dateFormat: 'd/m/Y',
        locale: Portuguese,
      },
      formData: {
        numberCpfCnpj: '',
        name: '',
        emailAddress: '',
        address: '',
        phoneNumber: '',
        surname: '',
        userName: '',
        totalReceived: '',
        cep: '',
        rua: '',
        numero: 1,
        complemento: '',
        bairro: '',
        cidade: '',
        uf: '',

        bank: {
          bankId: '',
          agency: '',
          agencyDigit: '',
          checkingAccount: '',
          checkingAccountDigit: '',
          bankDataId: '',
          id: '',
        },

        external: {
          website: '',
          deliveredWorks: '',
          foundationYear: '',
          about: '',
          actingRegions: '',
          awards: '',
          id: '',

          attachments: {
            binaryCnpj: '',
            negativeStateCertificateFile: '',
            negativeMunicipalCertificateFile: '',
            negativeFederalCertificateFile: '',
          },
        },
      },

      deliberationData: {
        situationProfile: '',
        rating: '',
        constructionCompanyRatingId: '',
        text: '',
      },

      deliberationDataChild: {
        negativeFederalCertificateSituation: '',
        negativeMunicipalCertificateSituation: '',
        negativeStateCertificateSituation: '',
        situationCnpj: '',
      },

      notify: {
        data_about_notify: false,
        data_actingRegions_notify: false,
        data_statusDocs_notify: false,
      },

      service: null,

      listBanks: [],

      options: [
        { value: 's', text: 'Sim' },
        { value: 'n', text: 'Não' },
      ],

      status: [
        { value: 2, text: 'Pendente Validação' },
        { value: 1, text: 'Aprovado' },
        { value: 3, text: 'Pendente Informação' },
        { value: 4, text: 'Rejeitado' },
      ],

      cleaveOptions: {
        cnpj: {
          delimiters: ['.', '.', '/', '-'],
          blocks: [2, 3, 3, 4, 2],
          uppercase: true,
          numericOnly: true,
        },
        money: {
          numeral: true,
          numeralDecimalMark: ',',
          delimiter: '.',
          prefix: 'R$ ',
          signBeforePrefix: true,
        },
        phone: {
          blocks: [2, 5, 4],
          numericOnly: true,
        },
      },
      errorRating: '',
      errorStatus: 0,
      required,
      listaUf: [],
      endpoint: null,
      endpoint_type: null,
      estadosCivis: [
        {
          title: 'Solteiro',
          value: 1,
        },
        {
          title: 'Casado',
          value: 2,
        },
        {
          title: 'Separado',
          value: 3,
        },
        {
          title: 'Divorciado',
          value: 4,
        },
        {
          title: 'Viúvo',
          value: 5,
        },
      ],
      listaNacionalidades: [
        {
          title: 'Brasileiro Nato',
          value: 1,
        },
        {
          title: 'Brasileiro Naturalizado',
          value: 2,
        },
        {
          title: 'Estrangeiro',
          value: 3,
        },
      ],
    }
  },

  created() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', false)
  },

  mounted() {
    if (!this.$route.params.usuario) {
      this.$router.push({ name: 'company-list' })
      return
    }
    this.getDataCompany()
    this.getSystemBank()
    this.getSystemRating()
    this.getEstates()
  },

  methods: {
    changeDate(id){
      formatter.validateDate(id, this)
    },
    
    externalData() {
      this.formData.external.about = this.$refs.data_form_external.dataExternal.about
      this.formData.external.actingRegions = this.$refs.data_form_external.dataExternal.actingRegions
      this.formData.external.awards = this.$refs.data_form_external.formDataChild.awards.content
      this.deliberationDataChild.negativeFederalCertificateSituation = this.$refs.data_form_external.formDataChild.status.negativeFederalCertificateSituation
      this.deliberationDataChild.negativeMunicipalCertificateSituation = this.$refs.data_form_external.formDataChild.status.negativeMunicipalCertificateSituation
      this.deliberationDataChild.negativeStateCertificateSituation = this.$refs.data_form_external.formDataChild.status.negativeStateCertificateSituation
      this.deliberationDataChild.situationCnpj = this.$refs.data_form_external.formDataChild.status.situationCnpj
      this.deliberationData.text = this.$refs.data_content.content
    },

    updateTotalReceived(value) {
      this.formData.totalReceived = value
    },

    registerData() {
      this.externalData()
      setTimeout(() => {
        this.register()
      }, 1000)
    },
    treatDataCompany() {
      const data = {
        situationCnpj: this.deliberationDataChild.situationCnpj,
        negativeMunicipalCertificateSituation: this.deliberationDataChild.negativeMunicipalCertificateSituation,
        negativeStateCertificateSituation: this.deliberationDataChild.negativeStateCertificateSituation,
        negativeFederalCertificateSituation: this.deliberationDataChild.negativeFederalCertificateSituation,
        situationProfile: this.deliberationData.situationProfile,
        userConstructionCompanyId: this.id,
        constructionCompanyRatingId: this.deliberationData.rating.id,
        text: this.deliberationData.text,
      }
      return data
    },

    treatInfosCompanyRegister() {
      const data = {
        website: this.formData.external.website,
        deliveredWorks: this.formData.external.deliveredWorks,
        foundationYear: this.formData.external.foundationYear,
        about: this.formData.external.about,
        actingRegions: this.formData.external.actingRegions,
        awards: this.formData.external.awards,
        userConstructionCompanyId: this.id,
        id: this.formData.external.id,
      }

      return data
    },
    treatDataCompanyRegister() {
      const data = {
        address: this.formData.address,
        zipcode: this.formData.cep,
        street: this.formData.rua,
        number: this.formData.numero,
        aditionalinfo: this.formData.complemento,
        neighborhood: this.formData.bairro,
        city: this.formData.cidade,
        uf: this.formData.uf,
        totalReceived: this.formData.totalReceived,
        situation: this.deliberationData.situationProfile,
        user: {
          userId: this.userId,
          name: this.formData.name,
          surname: this.formData.surname,
          userName: this.formData.userName,
          razaoSozial: this.formData.name,
          numberCpfCnpj: this.formData.numberCpfCnpj.replace(/[^\d]+/g, ''),
          emailAddress: this.formData.emailAddress,
          phoneNumber: this.formData.phoneNumber,
          typePerson: 'J',
        },
        id: this.id,
      }

      return data
    },
    treatBankCompanyRegister() {
      const data = {
        bankId: this.formData.bank.bankId,
        agency: this.formData.bank.agency,
        agencyDigit: this.formData.bank.agencyDigit,
        checkingAccount: this.formData.bank.checkingAccount,
        checkingAccountDigit: this.formData.bank.checkingAccountDigit,
        typePix: 1,
        key: 'string',
        userId: this.userId,
        id: this.formData.bank.id,
      }

      return data
    },

    async getDataCompany() {
      const getDataCompanyValidation = await this.getDataCompanyValidation()
      const dataConstructionCompany = await this.getDataCompanyDatas()
      const dataConstructionCompanyInfo = await this.getDataCompanyInfo()
      const dataConstructionCompanyFiles = await this.getDataCompanyFiles()
      const dataUserBankDatas = await this.getDataCompanyBank()

      const responsible = (await UserResponsibleService.GetResponsibleDataByUserId(this.userId)).data.result.responsibleData
      if(responsible !== null) {
        this.optionsResponsible = responsible
        this.optionsResponsible.birthDate = this.frontEndDateFormat(this.optionsResponsible.birthDate)
      }

      this.setDataCompany(getDataCompanyValidation, dataConstructionCompany, dataConstructionCompanyInfo, dataConstructionCompanyFiles, dataUserBankDatas)
    },
    frontEndDateFormat(v) {
      return Moment(v, 'YYYY-MM-DD').format('DD/MM/YYYY')
    },
    async getEstates(){
      const responseEstates = await StateService.getAll()
      this.listaUf = responseEstates.data.result.items.map(res => ({
        value: res.estate.id,
        text: res.estate.name,
      }))
    },

    async getDataCompanyValidation() {
      const result = await this.$http
        .get(`/api/services/app/ConstructionCompanyValidations/GetLastConstructionCompanyValidation?idConstructionCompany=${this.id}`, {
          headers: {
            accept: 'application/json',
            'X-XSRF-TOKEN': localStorage.getItem('encryptedAccessToken'),
          },
        })
        .then(res => res)
        .catch(error => error)

      return result
    },
    async getDataCompanyDatas() {
      const result = await ConstructionCompanyService.GetUserConstructionCompanyForEdit(this.id)
      this.userId = result.data.result.userConstructionCompany.userId
      return result
    },
    async getDataCompanyInfo() {
      const result = await ConstructionCompanyInfoService.GetInfoByUserConstructionCompanyAdm(this.id)
      return result
    },
    async getDataCompanyFiles() {
      const result = await ConstructionCompanyDataService.GetByUserConstructionCompanyAdm(this.id)

      return result
    },
    async getDataCompanyBank() {
      const response = await UserBankService.GetUserBankDataForUserId(this.userId)

      this.show = false
      return response
    },

    setDataCompany(getDataCompanyValidation, dataConstructionCompany, dataConstructionCompanyInfo, dataConstructionCompanyFiles, dataUserBankDatas) {
      if (getDataCompanyValidation.data.result) {
        this.deliberationDataChild.negativeFederalCertificateSituation = getDataCompanyValidation.data.result.constructionCompanyValidation.negativeFederalCertificateSituation
        this.deliberationDataChild.negativeMunicipalCertificateSituation = getDataCompanyValidation.data.result.constructionCompanyValidation.negativeMunicipalCertificateSituation
        this.deliberationDataChild.negativeStateCertificateSituation = getDataCompanyValidation.data.result.constructionCompanyValidation.negativeStateCertificateSituation
        this.deliberationDataChild.situationCnpj = getDataCompanyValidation.data.result.constructionCompanyValidation.situationCnpj
        this.deliberationData.constructionCompanyRatingId = getDataCompanyValidation.data.result.constructionCompanyValidation.constructionCompanyRatingId
        this.deliberationData.text = getDataCompanyValidation.data.result.constructionCompanyValidation.text
        // this.deliberationData.situationProfile = getDataCompanyValidation.data.result.constructionCompanyValidation.situationProfile
        this.deliberationData.rating = getDataCompanyValidation.data.result.constructionCompanyRatingNote
      }

      if (dataConstructionCompany.data.result) {
        this.formData.external.attachments.binaryCnpj = dataConstructionCompany.data.result.userConstructionCompany.binaryCnpj
        this.formData.address = dataConstructionCompany.data.result.userConstructionCompany.address

        this.formData.rua = dataConstructionCompany.data.result.userConstructionCompany.street
        this.formData.numero = dataConstructionCompany.data.result.userConstructionCompany.number
        this.formData.complemento = dataConstructionCompany.data.result.userConstructionCompany.aditionalInfo
        this.formData.cidade = dataConstructionCompany.data.result.userConstructionCompany.city
        this.formData.uf = dataConstructionCompany.data.result.userConstructionCompany.uf
        this.formData.cep = dataConstructionCompany.data.result.userConstructionCompany.zipcode
        this.formData.bairro = dataConstructionCompany.data.result.userConstructionCompany.neighborhood

        this.formData.phoneNumber = dataConstructionCompany.data.result.userConstructionCompany.user.phoneNumber
        this.formData.numberCpfCnpj = dataConstructionCompany.data.result.userConstructionCompany.user.numberCpfCnpj
        this.formData.name = dataConstructionCompany.data.result.userConstructionCompany.user.name
        this.formData.surname = dataConstructionCompany.data.result.userConstructionCompany.user.surname
        this.formData.userName = dataConstructionCompany.data.result.userConstructionCompany.user.userName
        this.formData.emailAddress = dataConstructionCompany.data.result.userConstructionCompany.user.emailAddress
        this.formData.totalReceived = dataConstructionCompany.data.result.userConstructionCompany.totalReceived
        this.deliberationData.situationProfile = dataConstructionCompany.data.result.userConstructionCompany.situation
      }

      if (dataConstructionCompanyInfo.data.result) {
        this.formData.external.website = dataConstructionCompanyInfo.data.result.website
        this.formData.external.id = dataConstructionCompanyInfo.data.result.id
        this.formData.external.deliveredWorks = dataConstructionCompanyInfo.data.result.deliveredWorks
        this.formData.external.foundationYear = dataConstructionCompanyInfo.data.result.foundationYear
        this.formData.external.about = dataConstructionCompanyInfo.data.result.about
        this.formData.external.actingRegions = dataConstructionCompanyInfo.data.result.actingRegions
        this.formData.external.awards = dataConstructionCompanyInfo.data.result.awards
      }

      if (dataConstructionCompanyFiles.data.result) {
        this.formData.external.attachments.negativeStateCertificateFile = dataConstructionCompanyFiles.data.result.negativeStateCertificateFile
        this.formData.external.attachments.negativeMunicipalCertificateFile = dataConstructionCompanyFiles.data.result.negativeMunicipalCertificateFile
        this.formData.external.attachments.negativeFederalCertificateFile = dataConstructionCompanyFiles.data.result.negativeFederalCertificateFile
      }

      if (dataUserBankDatas.data.result.userBankData) {
        this.formData.bank = dataUserBankDatas.data.result.userBankData
      }
    },

    async register() {
      try {
        const dataInfos = this.treatInfosCompanyRegister()

        console.log(dataInfos)
        dataInfos.userConstructionCompanyId = this.id
        ConstructionCompanyInfoService.CreateOrEdit(dataInfos).then()
          .catch(error => {
            this.show = false
            if (!this.msgIsOpen()) this.msgError(error.response.data.error.message)
            throw new Error(error.response.data.error.message)
          })

        const dataBank = this.treatBankCompanyRegister()
        dataBank.userId = this.userId
        UserBankService.CreateOrEdit(dataBank).then()
          .catch(error => {
            this.show = false
            if (!this.msgIsOpen()) this.msgError(error.response.data.error.message)
            throw new Error(error.response.data.error.message)
          })

        this.saveValidation()

        const dataCompany = this.treatDataCompanyRegister()
        dataCompany.userConstructionCompanyId = this.id
        //debugger
        ConstructionCompanyService.CreateOrEdit(dataCompany).then()
          .catch(error => {
            this.show = false
            if (!this.msgIsOpen()) this.msgError(error.response.data.error.message)
            throw new Error(error.response.data.error.message)
          })

        this.show = false
        if (!this.msgIsOpen()) this.msgSuccessNoConfirm('Dados gravados com sucesso')
        setTimeout(() => {
          if (this.$route.params.path === 'company') {
            this.$router.push({ name: 'company-list' })
          } else {
            this.$router.push({ name: 'deliberation-company-list' })
          }
        }, 2700)
      } catch (error) {
        this.show = false
        if (!this.msgIsOpen()) this.msgError(error.message)
      }
      await this.UpdateResponsible()
    },

    async saveValidation() {
      const data = this.treatDataCompany()
      const v = this.endpoint_type

      switch (v) {
        case 'save':
          this.endpoint = '/api/services/app/ConstructionCompanyValidations/Save'
          break
        case 'approve':
          this.endpoint = '/api/services/app/ConstructionCompanyValidations/Approve'
          break
        case 'reprove':
          this.endpoint = '/api/services/app/ConstructionCompanyValidations/Reprove'
          break
        default:
          this.endpoint = ''
      }
      const config = {
        validateStatus: () => true,
      }
      this.$http.post(this.endpoint, data, config).then()
        .catch(error => {
          this.show = false
          if (!this.msgIsOpen()) this.msgError(error.response.data.error.message)
          throw new Error(error.response.data.error.message)
        })
    },

    async getSystemBank() {
      const result = await this.$http.get('/api/services/app/Banks/GetAll', {
        headers: {
          accept: 'application/json',
          'X-XSRF-TOKEN': localStorage.getItem('encryptedAccessToken'),
        },
      })

      this.listBanks = result.data.result.items.map(res => ({
        value: res.bank.id,
        code: res.bank.code,
        text: res.bank.name,
        initials: res.bank.initials,
      }))
    },

    async getSystemRating() {
      const result = await this.$http.get('/api/services/app/ConstructionCompanyRatings/GetAll', {
        headers: {
          accept: 'application/json',
          'X-XSRF-TOKEN': localStorage.getItem('encryptedAccessToken'),
        },
      })
      this.ratings = result.data.result.items.map(res => ({
        id: res.constructionCompanyRating.id,
        note: res.constructionCompanyRating.note,
        description: res.constructionCompanyRating.description,
        color: res.constructionCompanyRating.color,
      }))
    },

    async UpdateResponsible() {
      const responsible = {
        id: this.optionsResponsible.id,
        postalCode: this.optionsResponsible.postalCode,
        civilState: this.optionsResponsible.civilState,
        cpf: this.optionsResponsible.cpf,
        district: this.optionsResponsible.district,
        name: this.optionsResponsible.name,
        nationality: this.optionsResponsible.nationality,
        phoneNumber: this.optionsResponsible.phoneNumber,
        profession: this.optionsResponsible.profession,
        rg: this.optionsResponsible.rg,
        estateId: this.optionsResponsible.estateId,
        city: this.optionsResponsible.city,
        street: this.optionsResponsible.street,
        streetNumber: this.optionsResponsible.streetNumber,
        birthDate: this.optionsResponsible.birthDate,
        userId: new Number(this.userId)
      }
      this.optionsResponsible.id = (await UserResponsibleService.CreateOrEdit(responsible)).data.result
    },

    rating(ran) {
      this.errorRating = ran.note
      this.deliberationData.rating = ran
    },
    goSite() {
      window.open(this.formData.external.website)
    },
    validationForm(v) {
 
      this.errorStatus = 0
      
      const { attachments } = this.formData.external
      const { status } = this.$refs.data_form_external.formDataChild

      if (this.deliberationData.situationProfile === 1) {
        if (status.situationCnpj != 1){
          this.errorStatus = 1
        }
        if (attachments.negativeStateCertificateFile.bytes !== '' && status.negativeStateCertificateSituation !== 1) {          
          this.errorStatus = 1
        }
        if (attachments.negativeMunicipalCertificateFile.bytes !== '' && status.negativeMunicipalCertificateSituation !== 1) {          
          this.errorStatus = 1
        }
        if (attachments.negativeFederalCertificateFile .bytes !== '' && status.negativeFederalCertificateSituation !== 1) {          
          this.errorStatus = 1
        }
      }
      
      this.endpoint_type = v

      setTimeout(() => {
        this.notify.data_about_notify = false
        this.notify.data_actingRegions_notify = false

        if (this.$refs.registerForm.errors['Sobre a construtora'][0] !== undefined) {
          this.notify.data_about_notify = true
        }
        if (this.$refs.registerForm.errors['Regiões de atuação'][0] !== undefined) {
          this.notify.data_actingRegions_notify = true
        }
      }, 1000)

      this.$refs.registerForm.validate().then(success => {
        const { rating } = this.deliberationData
        if (success && this.errorStatus === 0 && rating) {
          this.show = true
          this.registerData()
        } else if (!success || rating.length === 0) {
          this.errorRating = rating || 'show'

          if (!this.msgIsOpen()) this.msgError('Preencha os campos obrigatórios assinalados em vermelho')
        } else if (this.errorStatus === 1) {
          if (!this.msgIsOpen()) this.msgWarning('Na aba <strong>Documentos</strong>, todas as opções devem estar com status <strong>Aprovado</strong>')
        }
      })
    },

    setRoutePath() {
      if (this.$route.params.path === 'company') {
        this.routePath = this.$router.push({ name: 'company-list' })
      } else {
        this.routePath = this.$router.push({ name: 'deliberation-company-list' })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import './sass/variables.scss';
@import './sass/company';
body {
  background-color: #f8f8f8 !important;
}
.error_rating {
  display: block;
}

.custom-select:focus {
  border-color: var(--color) !important;
  box-shadow: 0 3px 10px 0 rgb(34 41 47 / 10%);
}

.custom-select:disabled {
  color: #3e3e40;
}
</style>
