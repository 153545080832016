<template>

    <validation-provider #default="{ errors }" name="Parecer" rules="required">
      <quill-editor v-model="content" :disable="disableChild" placeholder="Descrição..." required />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>

</template>

<script>
import { ValidationProvider } from 'vee-validate'
import { required } from '@validations'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'

export default {
  components: {
    quillEditor,
    ValidationProvider,

  },

  props: ['text', 'disable'],

  data: () => ({
    required,

    content: '',
    disableChild: 0,
  }),

  computed: {
    changeData() {
      const { text, disable } = this
      return {
        text,
        disable,
      }
    },
  },

  watch: {
    changeData: {
      handler(val) {
        this.content = val.text
        this.disableChild = val.disable
      },
      deep: true,
      immediate: true,
    },
  },

}
</script>
