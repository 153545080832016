<template>
   <vue-editor id="editor" v-model="content" :disabled="disableChild === 1" @change="change" useCustomImageHandler @image-added="handleImageAdded"> </vue-editor>
</template>

<script>
import toast from '@/mixins/toast'
import { VueEditor } from 'vue2-editor'

export default {
  components: {
    VueEditor,
  },
  mixins: [toast],
  props: ['text', 'disable'],

  data: () => ({
    content: '',
    errorFile: '',
    disableChild: 0,
  }),

  mounted() {
  },

  methods: {
    handleImageAdded: function handleImageAdded(file, Editor, cursorLocation, resetUploader) {
      if (file.size > 1024 * 1024) {
        this.msgError('O arquivo não pode ser maior que 1MB')
      } else {
        const formData = new FormData()
        formData.append('image', file)
        const reader = new FileReader()
        reader.readAsDataURL(file)
        this.errorFile = ''
        reader.onload = () => {
          Editor.insertEmbed(cursorLocation, 'image', reader.result)
        }
        reader.onerror = error => {
          this.msgError(error)
        }
        resetUploader()
      }
    },
    change($event){
      $emit('change', $event)
    }
  },

  computed: {
    changeData() {
      const { text, disable } = this
      return {
        text,
        disable,
      }
    },
  },

  watch: {
    changeData: {
      handler(val) {
        this.content = val.text
        this.disableChild = val.disable
      },
      deep: true,
      immediate: true,
    },
  },
}
</script>
