import Vue from 'vue'

const UserBankService = {

  CreateOrEdit(data) {
    return Vue.prototype.$http.post('/api/services/app/UserBankDatas/CreateOrEdit',
      data, { timeout: 300000 })
  },

  GetUserBankDataForUserId(userId) {
    return Vue.prototype.$http.get(`/api/services/app/UserBankDatas/GetUserBankDataForUserId?UserId=${userId}`,
      this.reset, { timeout: 300000 })
  },
}

export default UserBankService
