import Vue from 'vue'

const UserResponsibleService = {

  CreateOrEdit(data) {
    return Vue.prototype.$http.post('/api/services/app/ResponsibleDatas/CreateOrEdit',
      data, { timeout: 300000 })
  },

  GetResponsibleDataByUserId(userId) {
    return Vue.prototype.$http.get(`/api/services/app/ResponsibleDatas/GetResponsibleDataByUserId?UserId=${userId}`,
      this.reset, { timeout: 300000 })
  },
}

export default UserResponsibleService
