var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-card-group',{staticClass:"mb-0 mt-2",attrs:{"deck":""}},[_c('b-card',{staticClass:"img-size",staticStyle:{"max-width":"15rem"},attrs:{"img-alt":"Card image cap","border-variant":"light","no-body":""}},[_c('b-card-body',{staticClass:"title-upload"},[_c('b-card-title',{staticClass:"text-center ft-size"},[_vm._v("Cartão CNPJ")])],1),(_vm.attachments.binaryCnpj.id)?_c('b-link',{staticClass:"detailing__cursor",attrs:{"id":"link-cnpj","to":{ name: 'company-view-file' },"target":"_blank"},on:{"click":function($event){return _vm.setDataFile({id: _vm.attachments.binaryCnpj.id, data: _vm.attachments.binaryCnpj})}}},[_c('b-img',{attrs:{"fluid":"","src":require('@/assets/images/upload/upload_transparent_on.png')}})],1):_c('b-img',{attrs:{"id":"link-cnpj-disabled","fluid":"","src":require('@/assets/images/upload/upload_transparent.png')}}),_c('b-card-body',[_c('b-card-text',[_c('validation-provider',{attrs:{"name":"Cartão CNPJ","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"situationCnpj","disabled":_vm.disableChild === 1,"options":_vm.status_value},model:{value:(_vm.dataFormChild.situationCnpj),callback:function ($$v) {_vm.$set(_vm.dataFormChild, "situationCnpj", $$v)},expression:"dataFormChild.situationCnpj"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-card',{staticClass:"img-size",staticStyle:{"max-width":"15rem"},attrs:{"img-alt":"Card image cap","border-variant":"light","no-body":""}},[_c('b-card-body',{staticClass:"title-upload"},[_c('b-card-title',{staticClass:"text-center ft-size"},[_vm._v("Certidão Negativa Municipal")])],1),(_vm.attachments.negativeMunicipalCertificateFile !== null && _vm.attachments.negativeMunicipalCertificateFile.bytes !== '')?_c('b-link',{staticClass:"detailing__cursor",attrs:{"id":"link-municipal","to":{ name: 'company-view-file' },"target":"_blank"},on:{"click":function($event){return _vm.setDataFile({id: _vm.attachments.negativeMunicipalCertificateFile.id, data: _vm.attachments.negativeMunicipalCertificateFile, fileKey: 'filecnpj'})}}},[_c('b-img',{attrs:{"fluid":"","src":require('@/assets/images/upload/upload_transparent_on.png')}})],1):_c('b-img',{attrs:{"id":"link-municipal-disabled","fluid":"","src":require('@/assets/images/upload/upload_transparent.png')}}),_c('b-card-body',[_c('b-card-text',[_c('validation-provider',{attrs:{"name":"Certidão Negativa Municipal","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"negativeMunicipalCertificateSituation","disabled":_vm.disableChild === 1 || _vm.attachments.negativeMunicipalCertificateFile === null || _vm.attachments.negativeMunicipalCertificateFile.bytes === '',"options":_vm.status_value},model:{value:(_vm.dataFormChild.negativeMunicipalCertificateSituation),callback:function ($$v) {_vm.$set(_vm.dataFormChild, "negativeMunicipalCertificateSituation", $$v)},expression:"dataFormChild.negativeMunicipalCertificateSituation"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-card',{staticClass:"img-size",staticStyle:{"max-width":"15rem"},attrs:{"img-alt":"Card image cap","border-variant":"light","no-body":""}},[_c('b-card-body',{staticClass:"title-upload"},[_c('b-card-title',{staticClass:"text-center ft-size"},[_vm._v("Certidão Negativa Estadual")])],1),(_vm.attachments.negativeStateCertificateFile !== null && _vm.attachments.negativeStateCertificateFile.bytes !== '')?_c('b-link',{staticClass:"detailing__cursor",attrs:{"id":"link-state","to":{ name: 'company-view-file' },"target":"_blank"},on:{"click":function($event){return _vm.setDataFile({id: _vm.attachments.negativeStateCertificateFile.id, data:_vm.attachments.negativeStateCertificateFile, fileKey: 'filecnpj'})}}},[_c('b-img',{attrs:{"fluid":"","src":require('@/assets/images/upload/upload_transparent_on.png')}})],1):_c('b-img',{attrs:{"id":"link-state-disabled","fluid":"","src":require('@/assets/images/upload/upload_transparent.png')}}),_c('b-card-body',[_c('b-card-text',[_c('validation-provider',{attrs:{"name":"Certidão Negativa Estadual","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"negativeStateCertificateSituation","disabled":_vm.disableChild === 1 || _vm.attachments.negativeStateCertificateFile === null || _vm.attachments.negativeStateCertificateFile.bytes === '',"options":_vm.status_value},model:{value:(_vm.dataFormChild.negativeStateCertificateSituation),callback:function ($$v) {_vm.$set(_vm.dataFormChild, "negativeStateCertificateSituation", $$v)},expression:"dataFormChild.negativeStateCertificateSituation"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-card',{staticClass:"img-size",staticStyle:{"max-width":"15rem"},attrs:{"img-alt":"Card image cap","border-variant":"light","no-body":""}},[_c('b-card-body',{staticClass:"title-upload"},[_c('b-card-title',{staticClass:"text-center ft-size"},[_vm._v("Certidão Negativa Federal")])],1),(_vm.attachments.negativeFederalCertificateFile !== null && _vm.attachments.negativeFederalCertificateFile.bytes !== '')?_c('b-link',{staticClass:"detailing__cursor",attrs:{"id":"link-federal","to":{ name: 'company-view-file' },"target":"_blank"},on:{"click":function($event){return _vm.setDataFile({id: _vm.attachments.negativeFederalCertificateFile.id, data: _vm.attachments.negativeFederalCertificateFile, fileKey: 'filecnpj'})}}},[_c('b-img',{attrs:{"fluid":"","src":require('@/assets/images/upload/upload_transparent_on.png')}})],1):_c('b-img',{attrs:{"id":"link-federal-disabled","fluid":"","src":require('@/assets/images/upload/upload_transparent.png')}}),_c('b-card-body',[_c('b-card-text',[_c('validation-provider',{attrs:{"name":"Certidão Negativa Federal","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"negativeFederalCertificateSituation","disabled":_vm.disableChild === 1 || _vm.attachments.negativeFederalCertificateFile === null|| _vm.attachments.negativeFederalCertificateFile.bytes === '',"options":_vm.status_value},model:{value:(_vm.dataFormChild.negativeFederalCertificateSituation),callback:function ($$v) {_vm.$set(_vm.dataFormChild, "negativeFederalCertificateSituation", $$v)},expression:"dataFormChild.negativeFederalCertificateSituation"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }