import Vue from 'vue'

const StateService = {

  getAll() {
    return Vue.prototype.$http.get('/api/services/app/Estates/GetAll')
  },
}

export default StateService
