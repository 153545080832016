<template>
    <div>
        <b-button  style="width: 15em; margin-bottom:1em" class="btn-block" variant="primary" @click="$refs.modalNew.show()">Adicionar comentário</b-button>
        <h2><b>Comentários</b></h2>
        <div class="subtitle">Deixe seu comentário/solicitação para a Divvy</div>        
        <div v-for="coment in coments" :key="coment.text">
            <b-row class="pt-1">
            <b-col>
                <b-card border-variant="dark">
                <b-card-text>
                    <b-row align-h="between">
                    <b-col cols="4">
                        <span><strong>{{ coment.userName }}</strong> comentou em {{ formatDate(coment.constructionCompanyComments.date) }} às {{ formatMinute(coment.constructionCompanyComments.date) }}</span>
                    </b-col>
                    <b-col v-if="coment.constructionCompanyComments.showOptions" style="margin-left: 2em" cols="2">
                        <b-link title="Editar" @click="edit(coment.constructionCompanyComments)">
                            <feather-icon icon="Edit2Icon" class="mr-50" />
                        </b-link>
                        <b-link  style="margin-left: 0.5em"  title="Editar" @click="remove(coment.constructionCompanyComments.id)">
                            <feather-icon icon="TrashIcon" class="mr-50" />
                        </b-link>
                    </b-col>
                    </b-row>
                </b-card-text>
                <br><br>
                <b-row class="pt-1">
                    <b-col>
                    <span>{{ coment.constructionCompanyComments.text }}</span>
                    </b-col>
                </b-row>
                </b-card>
            </b-col>
            </b-row>
        </div>
        <b-modal ref="modalNew" @ok="create" @cancel="clear">
            <b-form-textarea placeholder="Deixe seu comentário" v-model="text">
            </b-form-textarea>
        </b-modal>
    </div>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BFormTextarea, BCardFooter, BAvatar, BLink, BButton, BModal,
} from 'bootstrap-vue'
import CompanyCommentsService from '@/services/Comments/CompanyCommentsService'
import formatter from '@/mixins/formatter'

export default {
    components: {
      BCard, 
      BCardHeader, 
      BFormTextarea,
      BCardBody, 
      BCardFooter, 
      BAvatar, 
      BLink,
      BButton, 
      BModal,
    },
    props:['companyId'],
  data() {
    return {
        id: null,
        text: null,
        coments: [],
    }
  },

  methods: {
      async getComments(){
          this.coments = (await CompanyCommentsService.getAll(this.companyId)).data.result.items
      },
      
        formatDate(dateString) {
            return formatter.formatDate(dateString)
        },
        formatMinute(dateString){
            const date = new Date(dateString)
            const hours = date.getHours()
            const min = date.getMinutes()
            return `${hours > 9 ? hours : '0'+ hours}:${min > 9 ? min : '0'+ min}`
        },
      async create(){
          const comment = {
              id: this.id,
              text: this.text,
              userConstructionCompanyId: this.companyId
          }
          await CompanyCommentsService.createOrEdit(comment)
          await this.getComments()
          this.clear()
      },
      async edit(comment){
          this.id = comment.id
          this.text = comment.text
          this.$refs.modalNew.show()
      },
      clear(){
        this.id = undefined
        this.text = undefined
      },
      async remove(id){
          await CompanyCommentsService.delete(id)
          await this.getComments()
      },
  },

  async mounted(){
    await this.getComments()
  }
}
</script>
<style>
    .box-style {
        border: 1px solid !important;
        padding: 0.5em;
    }
    .subtitle {
        font-size: 1.3em !important;
        margin-bottom: 1em;
    }
    .msg-title{
        font-size: 1.3em !important;
    }

    .msg-content {
        font-size: 1.5em !important;
    }
</style>