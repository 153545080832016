import Vue from 'vue'

const ConstructionCompanyService = {
  // BINARY
  CreateOrEdit(dataCompany) {
    return Vue.prototype.$http.post('/api/services/app/UserConstructionCompanies/CreateOrEdit',
      dataCompany, {
        timeout: 300000,
      })
  },

  ValidarPermissaoPublicarImovel() {
    return Vue.prototype.$http.post('/api/services/app/UserConstructionCompanies/ValidarPermissaoPublicarImovel',
      this.reset, {
        timeout: 300000,
      })
  },

  GetUserConstructionCompanyForEdit(id) {
    return Vue.prototype.$http.get(`/api/services/app/UserConstructionCompanies/GetUserConstructionCompanyForEdit?Id=${id}`,
      this.reset, {
        timeout: 300000,
      })
  },

  getResumeDashboard() {
    return Vue.prototype.$http.get(`/api/services/app/UserConstructionCompanies/GetDashboard`,
      this.reset, {
        timeout: 300000,
      })
  },
}

export default ConstructionCompanyService
